/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}body{line-height:1}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent}ins{background-color:#ff9;color:#000;text-decoration:none}mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}input,select{vertical-align:middle}

html {
   -ms-touch-action: manipulation;
   touch-action: manipulation;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes blink {
	0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}

@keyframes animLand {
   0% { background-position: 0px 0px; }
   100% { background-position: -335px 0px; }
}

@keyframes animSky {
   0% { background-position: 0px 100%; }
   100% { background-position: -275px 100%; }
}

@keyframes animBird {
   from { background-position: 0px 0px; }
   to { background-position: 0px -96px; }
}

@keyframes animPipe {
   0% { left: 650px; }
   100% { left: -100px; }
}

@keyframes animCeiling {
   0% { background-position: 0px 0px; }
   100% { background-position: -63px 0px; }
}

*,
*:before,
*:after
{
   /* border box */
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   /* gpu acceleration */
   -webkit-transition: translate3d(0,0,0);
   /* select disable */
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

html,
body
{
   height: 100%;
   overflow: hidden;
   font-family: monospace;
   font-size: 12px;
   color: #fff;
}

#gamecontainer
{
   position: relative;
   width: 100%;
   height: 100vh;
}

/*
Screen - Game
*/
#gamescreen
{
   position: absolute;
   width: 100%;
   height: 100%;
}

#sky
{
   position: absolute;
   top: 0;
   width: 100%;
   height: 80%;
   background-image: url('../assets/sky.png');
   background-repeat: repeat-x;
   background-position: 0px 100%;
   background-color: #4ec0ca;

   animation: animSky 7s linear infinite;
}

#flyarea
{
   position: absolute;
   bottom: 0;
   height: 98%;
   width: 100%;
}

#ceiling
{
   position: absolute;
   top: -16px;
   height: 16px;
   width: 100%;
   background-image: url('../assets/ceiling.png');
   background-repeat: repeat-x;

   animation: animCeiling 481ms linear infinite;
}

#land
{
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 20%;
   background-image: url('../assets/land.png');
   background-repeat: repeat-x;
   background-position: 0px 0px;
   background-color: #ded895;

   animation: animLand 2516ms linear infinite;
}

.state-Playing #bigscore,
.state-PlayerDying #bigscore
{
   visibility: visible;
}

#bigscore
{
   visibility: hidden;
   display: inline-block;
   z-index: 100;
}

#bigscore img
{
   display: inline-block;
   padding: 1px;
}

.screen-centered {
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   width: 100vw;
   height: 100vh;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

#splash
{
   opacity: 0;
   width: 188px;
   height: 170px;
   background-image: url('../assets/splash.png');
   background-repeat: no-repeat;

   transition: all 2000ms ease;
}

#splash.visible
{
   opacity: 1;
}

#scoreboard
{
   width: 236px;
   height: 280px;
   background-image: url('../assets/scoreboard.png');
   background-repeat: no-repeat;
   z-index: 1000;

   /* Hidden by default, and popped down slightly */
   visibility: hidden;
   opacity: 0;
   transform: translate3d(0px, 40px, 0px);

   transition: all 600ms ease;
}

#scoreboard.visible
{
   visibility: visible;
   opacity: 1;
   transform: translate3d(0px, 0px, 0px);
}

#scoreboard.slide-up
{
   opacity: 0;
   transform: translate3d(0px, -30px, 0px);
}

#medal
{
   position: absolute;
   opacity: 0;
   top: 114px;
   left: 32px;
   width: 44px;
   height: 44px;
   transform: scale(2);
   transition: all 1200ms ease;
}

#medal.visible {
   opacity: 1;
   transform: scale(1);
}

#currentscore
{
   position: absolute;
   top: 105px;
   left: 107px;
   width: 104px;
   height: 14px;
   text-align: right;
}

#currentscore img
{
   padding-left: 2px;
}

#highscore
{
   position: absolute;
   top: 147px;
   left: 107px;
   width: 104px;
   height: 14px;
   text-align: right;
}

#highscore img
{
   padding-left: 2px;
}

#replay
{
   position: absolute;
   top: 205px;
   left: 61px;
   height: 70px;
   width: 115px;
   cursor: pointer;

   /* Hidden by default, and popped down slightly */
   opacity: 0;
   transform: translate3d(0px, 40px, 0px);

   transition: all 600ms ease;
}

#replay.visible
{
   opacity: 1;
   transform: translate3d(0px, 0px, 0px);
}

#debug
{
   position: relative;
   z-index: 9999;
}

#debug-state
{
   position: absolute;
   top: 5px;
   left: 5px;
   font-size: 14px;
   background: rgba(0, 0, 0, 0.35);
   z-index: 99;
}

#debug-logs
{
   position: absolute;
   bottom: 0px;
   left: 700px;
   white-space: pre;
   background: rgba(0, 0, 0, 0.35);
   z-index: 99;
}

.boundingbox
{
   position: absolute;
   pointer-events: none;
   top: 0;
   left: 0;
   width: 0;
   height: 0;
   border: 1px solid red;
}

.bird
{
   position: absolute;

   left: 60px;
   width: 34px;
   height: 24px;
   background-image: url('../assets/bird.png');
   will-change: transform;

   animation: animBird 300ms steps(4) infinite;
}

.pipe
{
   position: absolute;
   left: -100px;
   width: 52px;
   height: 100%;
   z-index: 10;

   /* Originally, this was 7500. Coupled with the animPipe distance of
   moving each pipe left 1000px over the duration of the animation, it
   makes it quite easy to adjust these two values in tandem. For each
   pixel you move the pipe, multiply the distance by 7.5 to get the
   total required animation time. For a 750px animation, use 5625ms */
   animation: animPipe 5625ms linear;
}

.pipe_upper
{
   position: absolute;
   top: 0;
   width: 52px;
   background-image: url('../assets/pipe.png');
   background-repeat: repeat-y;
   background-position: center;
}

.pipe_upper:after
{
   content: "";
   position: absolute;
   bottom: 0;
   width: 52px;
   height: 26px;
   background-image: url('../assets/pipe-down.png');
}

.pipe_lower
{
   position: absolute;
   bottom: 0;
   width: 52px;
   background-image: url('../assets/pipe.png');
   background-repeat: repeat-y;
   background-position: center;
}

.pipe_lower:after
{
   content: "";
   position: absolute;
   top: 0;
   width: 52px;
   height: 26px;
   background-image: url('../assets/pipe-up.png');
}

#footer
{
   position: absolute;
   bottom: 3px;
   left: 3px;
}

#footer a
{
   display: block;
}

#footer a,
#footer a:link,
#footer a:visited,
#footer a:hover,
#footer a:active
{
   padding: 2px;
   text-decoration: none;
   color: #fff;
}

#game-options
{
   margin-bottom: 5px;
}

#game-options a
{
   display: inline-block;
}